<template>
 <div class="page-container">
	 <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
	 <div class="page-desc">
	  资产使用人(在职)汇表
	 </div>
	   <el-form ref="form" :inline="true"  :model="search_key" prop="" style="position: relative;">
		   <div  style="position: absolute;right:0px;z-index: 999;">
		   	<el-button  type="primary" @click="handleExport">导出全部</el-button> 
		   </div>
	       <el-form-item label="">
	           <el-input v-model="search_key.name" placeholder="请输入关键字搜索" style="width:200px;"></el-input>
	       </el-form-item>
	       <el-button type="primary" @click="getData()">查询</el-button>
	   </el-form>	
	   
	  <!-- 	row-key="id" :default-expand-all="false" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" -->
	  <el-table
	     :data="tableData" 
	     border>
		<el-table-column
		    prop="corp_name"
		    label="人员姓名">
			<template slot-scope="scope">
				<img style="width: 35px;height: 35px;border-radius:5px;margin-right: 10px;" :src="scope.row.avatar"/>
				<span>{{scope.row.corp_name}}</span>
			</template>
		</el-table-column>
	     <el-table-column
	         prop="department_name"
	         label="所在部门">
	     </el-table-column>
	      <el-table-column
			width="500"
	         prop="count"
			 sortable
	         label="资产总数">
	     </el-table-column>
	     <el-table-column 
	         prop="sum"
			 sortable
	         label="购置金额(含税)">
	     </el-table-column>
	  </el-table>
	  <pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
 </div>
</template>

<script>
export default {
	 inject:['reload'],
    data() {
        return {
			search_key:{
				name:''
			},
			tableData:[],
			currentPage:1,
			pagesize:10,
			total:0,
        }
    },
    created() {
		this.getData()
    },
    mounted() {

    },
    methods:{
		getData(){  
		    this.$api.get('AssetReport/user',{
				name:this.search_key.name,
				page:this.currentPage,
				limit:this.pagesize,
			},res=>{
		        if(res.code == 200){
		             this.tableData =  res.data.list
					 this.total =  res.data.count
		        }else{
		            this.$message.error(res.msg)
		        }
		    })
		},
		handleExport(){
			location.href = '/api/admin/AssetReport/exportUser'
		},
		getCurrPage(val){   //获取当前页
		    this.currentPage =  val
		    this.getData()
		},
		getPageSize(val){   //获取每页条数
		    this.pagesize =  val
		     this.getData()
		},	
    },
}
</script>
<style scoped lang="scss">
</style>